import React, { useEffect }  from 'react';
//Import CSS of Bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
//Import CSS of React Toastify
import 'react-toastify/dist/ReactToastify.css';

//Import CSS of Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Import CSS of Owl Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
//Import CSS for ow.carousel
// import 'owl.carousel/dist/assets/owl.carousel.css';

//Other Imports
import Routing from './Routes/Routing';
import { send } from './push';
import { env } from './env';
import axios from 'axios';

function App() {
  const VAPID_KEY = env.VAPID_KEY;
  const userSelection = async (status, msisdn) => {
    try {
      const resp = await axios.get(`https://pushnotify.gameit.in/api/subscribe?status=${status}&service=z-games&msisdn=${msisdn}`);
      const data = await resp.data;
      return true;
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);

    const ani =  queryParam.get("ani");
    const msisdn = ani ? ani : localStorage.getItem("msisdn");

    // console.log(msisdn, "this is msisdn....");
    setTimeout(() => {
    if(Notification.permission === "default") {
      Notification.requestPermission( async (permission) => {
        if(permission === "granted") {
          send( VAPID_KEY, msisdn )
          .catch(err => {
            console.log(err)
            // if(err) {
            //   send( VAPID_KEY, msisdn );
            // }
          });
          await userSelection("Allowed", msisdn);
          return;
        } else {
          await userSelection("Cancled", msisdn);
          return;
        };
      });
    };
  }, 4000);
  }, []);
  return (
    <>
      <Routing/>
    </>
  );              
}

export default App;