export async function send( VAPID_KEY, msisdn ) {
  
   await navigator.serviceWorker.register("./service-worker.js", {
    scope: "/"
  }).then( async (reg) => {
    var serviceWorkers;
      if(reg.active) {
        serviceWorkers = reg.active 
      } else if (reg.installing) {
        serviceWorkers = reg.installing
      } else if (reg.waiting) {
        serviceWorkers = reg.waiting
      }; 
      if(serviceWorkers) {
        if(serviceWorkers.state === "activated"){
          console.log("sw activated...");
          await sendPush(VAPID_KEY, msisdn, reg);
        } 
        serviceWorkers.addEventListener('statechange', async function(e) {
            if(e.target.state === "activated") {
              console.log('sw now activated...');
                await  sendPush(VAPID_KEY, msisdn, reg);
            };
        });
      };
  }).catch(err => {
    console.log(err, "ws reg error");
  });
};

async function sendPush (VAPID_KEY, msisdn, register) {
  const subscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(VAPID_KEY),
  });
  
  await fetch(`https://pushnotify.gameit.in/api/subscribe`, {
    method: "POST",
    body: JSON.stringify(subscription),
    headers: {
      "content-type": "application/json",
    }
  }).then(res =>  {}).catch(err => console.log(err));

};

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
