import React from "react";
import just_gamers_img from "../Media/Images/images/just_gamers_img.png";
import gamers_circle_line from "../Media/Images/images/gamers_circle_line.png";
import gamers_circle_shape from "../Media/Images/images/gamers_circle_shape.png";
import just_gamers_chart from "../Media/Images/images/just_gamers_chart.png";
import "../CSS/new.css";
import { savePlaysCountApi } from "../Data/data";
import PostRequest from "../API/PostRequest";

const SectionTwo = (data) => {

  //Method to handle play game
  const handlePlayGame=(e)=>{ 
    // console.log("e ",e);
    // console.log("id ",e.id);

    //Send count to increase in plays
    let request={"id":e.id};
    let promise=PostRequest(savePlaysCountApi,request);
    promise.then(e=>{
      // console.log("e ",e);
    })

    //Sending to Game
    window.location.href=e.game;

  }

  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="section-title title-style-three white-title mb-70">
              <h2>
                TOP THREE <span>GAMES</span>
              </h2>
              {/* <p>
                Till now 100k+ people play and enjoy these games. Playing games
                can contribute to making you happy, perhaps even without
                therapy.
              </p> */}
            </div>
            <div className="just-gamers-list">
              <ul>
                {data.data.map((value, key) => {
                  return (
                    <li key={key}>
                      <div
                        style={{cursor:"pointer"}}
                        className="just-gamers-list-icon"
                        onClick={() => {
                          handlePlayGame(value);
                        //  window.location.href=`${value.game}`;
                        }}
                      >
                        <img
                          src={value.image}
                          alt="ImgSrc"
                          height="80px"
                          width="80px"
                        />
                      </div>
                      <div
                        className="just-gamers-list-content fix"
                        style={{cursor:"pointer"}}
                        onClick={() => {
                          handlePlayGame(value);
                          // window.location.href=`${value.game}`;
                        }}
                      >
                        <h5 className="prevent-select">{value.name.toUpperCase()}</h5>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 d-none d-lg-block">
            <div className="just-gamers-img">
              <img
                src={just_gamers_img}
                //   src="img/images/just_gamers_img.png"
                alt=""
                className="just-gamers-character"
              />
              <div className="just-gamers-circle-shape">
                <img
                  src={gamers_circle_line}
                  //   src="img/images/gamers_circle_line.png"
                  alt=""
                />
                <img
                  src={gamers_circle_shape}
                  // src="img/images/gamers_circle_shape.png"
                  alt=""
                  className="rotateme"
                />
              </div>
              <img
                src={just_gamers_chart}
                // src="img/images/just_gamers_chart.png"
                alt=""
                className="gamers-chart-shape"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionTwo;