import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import SlickSliderTop from "../Components/SlickSliderTop";
import SlickSliderBottom from "../Components/SlickSliderBottom";
import OwlSlider from "../Components/OwlSlider";
import SectionTwo from "../Components/SectionTwo";
import SectionThree from "../Components/SectionThree";
import Footer from "../Components/Footer";
import Preloader from "../Components/Preloader";
import GetRequest from "../API/GetRequest";
import { getGamesApi,checkSubApi } from "../Data/data";
import PostRequest from "../API/PostRequest";
import { ToastContainer } from "react-toastify";
import "../CSS/new.css";

const Home = () => {

  //To Call on Page Load
  useEffect(()=>{
    getRequestParameter(); //To get request parameter i.e ani from URL
    // eslint-disable-next-line
  },[]);

  //To Go on any other page of project
  const navigate=useNavigate();

  //Get RequestParameter
  const getRequestParameter=()=>{
    let params=new URLSearchParams(window.location.search);
    let ani=params.get("ani");

    if(ani!==null)
    {
      localStorage.setItem("msisdn",ani);
    }
    // console.log("ani is ",ani);
    checkSubscription(ani);
  }

  //To Check Subscription
  const checkSubscription=(ani)=>{
  
    let request;
    if(ani===null)
    {
      //i.e ani is not in request parameter so getting from local storage
      // console.log("Picking ani from Local Storage");
      let msisdn=localStorage.getItem("msisdn"); 

      // console.log("msisdn is :: ",msisdn);
      if(msisdn===null || msisdn==undefined)
      {
        //i.e ani is also no in local storage
        window.location.href="https://nigergames.com/join.jsp";
      }

      request={"ani":msisdn};
    }
    else
    {
      // console.log("Picking ani from url");
      request={"ani":ani};
    }

    let promise=PostRequest(checkSubApi,request);
    promise.then(e=>{
      // console.log("e ",e);

      if(e.message==='Network Error')
      {
        navigate("/error");
      }

      handleSubscriptionResponse(e);
    })
  }

  //Method to Handle Subscription Api Response;
  const handleSubscriptionResponse=(e)=>{
    if(e.response==='Success')
    {
      // console.log("User Subscribed");
      localStorage.setItem("user","user");
      // toast.success("Welcome");
      getGamesFromBackend(); //then only we need games data
    }
    else
    {
      // console.log("User Not Subscribed");
      window.location.href="https://nigergames.com/join.jsp";
    }
  }

  //Method to Call Games From Backend
  const getGamesFromBackend=()=>{
      const promise=GetRequest(`${getGamesApi}?ani=${localStorage.getItem("msisdn")}`);
      promise.then(e=>{
      // console.log("data ",e.response);

      if(e.message==='Network Error')
      {
        // console.log("Backend is Not Working");
        navigate("/error");
      }

      processGamesData(e.response);
      gettingThreeGames(e.response);

    })
  };

  //State to Store Games Data
  const[data,setData]=useState([]);

  //Setting Data in State
  const processGamesData=(games)=>{
    // console.log("games ",games);
    setData(games);
    setLoaderState(true);
    setDivState('none');
  }

  let count=0;

  //Hook to store three games array
  const[threeGamesArray,setThreeGamesArray]=useState([]);

  //Method to get Three Games randomly
  const gettingThreeGames=(games)=>{
    games.forEach(e=>{
      if(e.category==='mostPlayed')
      {
        // console.log("e ",e);
        let arr=e.game.splice(0,3);
        // console.log("arr ",arr);
        setThreeGamesArray(arr);
      }
    })
  }

  //Loader
  let Loader=require('react-loader');
  let options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.00,
    corners: 1,
    color: 'white',
    opacity: 0.85,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
  };

  //State for Loader
  const[loaderState,setLoaderState]=useState(false);

  //Hook to store starting div state(loading div)
  const[divState,setDivState]=useState('block');

  return (
    <>
      <div className="loading-div" style={{display:`${divState}`}}>
        <Loader loaded={loaderState} options={options} className="spinner" />
      </div>

      <ToastContainer/>
      <Preloader/>
      <Header data={data}/>
     
      <main>
        <SectionOne data={data}/>
        <SlickSliderTop />

        <div className="area-bg-one">

          {data.length>0 && data.map((e,key)=>{
            count=count+1;
            // console.log(count);
            // console.log("e ",e);
            if(e.category==='mostPlayed')
              return(<span key={key}></span>)
            else
              return(
                <div key={key}>
                  <OwlSlider
                      list={e.game}
                      data={data}
                      key={key}
                      id={e.category}
                      name={e.category}
                    />
                    {count===2?<SectionTwo key={count} data={threeGamesArray}/>:<></>}
                    {count===4?<SectionThree list={data} key={count}/>:<></>}
                    {count===8?<SlickSliderBottom key={count}/>:<></>}
                </div>
              )
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Home;