// const pre=`http://localhost:2929/`;
// const pre=`http://5.189.166.187:5551/ZgamesNewGamePortalBackend/`;
const pre=`https://zamanigames.gameit.in/`;

const getGamesApi=`${pre}sendGames`;
export{getGamesApi};

const checkSubApi=`${pre}checkSubscription`;
export{checkSubApi};

const savePlaysCountApi=`${pre}savePlaysCount`;
export{savePlaysCountApi};