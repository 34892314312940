import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import slide1 from "../Media/Images/slider/slider_bg.jpg";
import slide2 from "../Media/top/bg1.webp";
import slide3 from "../Media/top/bg2.jpg";
import slide4 from "../Media/top/bg3.jpg";
import slide5 from "../Media/top/bg4.jpg";
import slide6 from "../Media/top/bg5.jpg";

const SectionOne = (data) => {

  //To go on any other page
  const navigate=useNavigate();

  useEffect(()=>{
    settingImage();
    // eslint-disable-next-line
  },[]);

  //Setting Random Image
  const[image,setImage]=useState(slide1);
  const settingImage=()=>{
    //getting random number b/w 1 to 10
    let x = Math.floor((Math.random() * 10) + 1);

    if(x===1 || x===7)
    {
      setImage(slide1);
    }
    else if(x===2 || x===8)
    {
      setImage(slide2);
    }
    else if(x===3)
    {
      setImage(slide3);
    }
    else if(x===4 || x===9)
    {
      setImage(slide4);
    }
    else if(x===5)
    {
      setImage(slide5);
    }
    else if(x===6 || x===10)
    {
      setImage(slide6);
    }
  }

  
  //To handle play games
  const handlePlayGames=()=>{
    data.data.forEach(e=>{
      if(e.category==='mostPlayed')
      {
        // console.log("selected ",e.game);
        localStorage.setItem("games",JSON.stringify(e.game));
        navigate("/seeAll");
      }
    })
  }

  return (
    <section className="slider-area slider-bg" style={{backgroundImage:`url(${image})`}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <div className="slider-content text-center">
              <h6 className="wow fadeInDown" data-wow-delay=".2s">
                islands
              </h6>
              <h2 className="tlt fix" data-in-effect="fadeInLeft">
                Z- <span>GAMING</span>
              </h2>
              <p className="wow fadeInUp" data-wow-delay="2s">
                FIND NEW GAMES BASED ON NEW TECHNOLOGY WHICH ARE FULL WITH FUN,
                ENTERTAINMENT AND KNOWLEDGE.
              </p>
              <span className="btn wow fadeInUp" data-wow-delay="2.2s"
                onClick={()=>{
                  handlePlayGames();
                }}>
                Play Games
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionOne;