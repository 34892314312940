import axios from "axios"

const PostRequest=async(url,request)=>{

    let sendResponse;

    await axios.post(url,request)
    .then(
        (response)=>{
            // console.log("response ",response.data);
            sendResponse=response.data;
        },
        (error)=>{
            console.log("error ",error);
            sendResponse=error;
        }
    )
    return sendResponse;
}
export default PostRequest;