import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SectionOne from "../Components/SectionOne";
import Home from "./Home";
import { savePlaysCountApi } from "../Data/data";
import PostRequest from "../API/PostRequest";

let user;

const SeeAll = () => {
  //Getting On Page Load
  useEffect(() => {
    getLocalStorageData();
    // eslint-disable-next-line
  }, []);

  //State to Store Selected Game Data
  const [data, setData] = useState([]);

  //Getting Data From Local Storage
  const getLocalStorageData = () => {
    user = localStorage.getItem("user");
    let games = JSON.parse(localStorage.getItem("games"));
    setData(games);
  };

  //Method to play game
  const handlePlayGame=(e)=>{
    // console.log("e ",e);
    // console.log("id ",e.id);

    //Send Request for save plays count
    let request={"ani":localStorage.getItem("msisdn"),"id":e.id};
    let promise=PostRequest(savePlaysCountApi,request);
    promise.then(e=>{
      // console.log("e ",e);
    })

    //Sending on game
    window.location.href=e.game;
  }

  if (user==="user") {
    return (
      <>
        <Header />
        <SectionOne />
        <div className="area-bg-one">
          <section className="upcoming-games-area upcoming-games-bg pt-120">
            <div className="container">
              <div className="row">
                {data.map((value, index) => {
                  return (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="upcoming-game-item mb-40">
                        <div className="upcoming-game-head">
                          <div className="uc-game-head-title">
                            <h4>
                              <span
                                onClick={() => {
                                  handlePlayGame(value);
                                  // window.location.href = value.game;
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {value.name}
                              </span>
                            </h4>
                          </div>
                          <div className="uc-game-price">
                            <h5>{value.rating}</h5>
                          </div>
                        </div>
                        <p>
                          Compete with players remote island winner takes
                          showdown known issue.
                        </p>
                        <div className="upcoming-game-thumb">
                          <img
                            src={value.image}
                            alt="Src"
                            height="200px"
                            width="200px"
                          />
                          <div className="upcoming-game-cart">
                            <a
                              href="#/"
                              onClick={()=>{
                                handlePlayGame(value);
                              }}
                              className="btn transparent-btn"
                            >
                              <i className="fas fa-shopping-basket"></i>Play
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Home/>        
      </>
    );
  }
};
export default SeeAll;